
export const getSellerId = {
    GET_SELLER_ID_FROM_AUTH : 'GET_SELLER_ID_FROM_AUTH',
    GET_SELLER_ID_FROM_BUY_SERVICE: 'GET_SELLER_ID_FROM_BUY_SERVICE',
    GET_SELLER_ID_FROM_SELLER_CARD: 'GET_SELLER_ID_FROM_SELLER_CARD',
    REMOVE__SELLER_ID_FROM_AUTH : 'REMOVE__SELLER_ID_FROM_AUTH',
    REMOVE_SELLER_ID_FROM_BUY_SERVICE : 'REMOVE_SELLER_ID_FROM_BUY_SERVICE',
    REMOVE_SELLER_ID_FROM_SELLER_CARD: 'REMOVE_SELLER_ID_FROM_SELLER_CARD'

}

export const getUserId = {
    GET_USER_ID: 'GET_USER_ID',
    REMOVE_USER_ID: 'REMOVE_USER_ID'
}
export const getrideId = {
    GET_RIDE_ID: 'GET_RIDE_ID',
    REMOVE_USER_ID: 'REMOVE_USER_ID'
}

