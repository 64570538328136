import { combineReducers } from 'redux';

import { getSellerIdFromAuthentication} from './getSellerIdFromAuthReducer';

import { getRidefromCreate } from './getRideIdRoot';

const rootReducer = combineReducers({
    
    get_seller_profile_id: getSellerIdFromAuthentication,
    get_Ride_id:getRidefromCreate

});
export default rootReducer;