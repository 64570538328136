import { getrideId} from "./actionTypes";

export const getRideId = (Ride_id) => {
    return {
        type: getrideId.GET_RIDE_ID,
        payload: {
            Ride_id,
           
      
        }
        
    };
};

export const removePay = (removed) => {
    return {
        type: getrideId.REMOVE_RIDE_ID,
        payload: removed
    };
};