import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { getUserIdFromAuth } from "../Redux/actions/GetSellerIdFromAuthActionCreators";
import { getRideId } from "../Redux/actions/getRideId";
import { useParams } from "react-router-dom";
function UpdateRide() {
  const baseUrl = "https://server.youthbuzz.in";
  const { rideID } = useParams("");

  const id = useSelector((state) => state.get_seller_profile_id.user_id);
  const role2 = useSelector((state) => state.get_seller_profile_id.role);
  const ride_id = useSelector((state) => state.get_Ride_id.Ride_id);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState("");
  const [role, setRole] = useState("ArcadeAdmin");
  const [search, setSearch] = useState("");
  const [button, showButton] = useState(true);
  const [filteredData, setFilteredData] = useState({
    session: [],
    achievement: [],
  });

  const [rideName, setRideName] = useState("");
  const [rideId, setRideId] = useState("");
  const [rideDescription, setRideDescription] = useState("");
  const [rideLocation, setRideLocation] = useState("");
  const [rideThumnail, setRideThumnail] = useState("");
  const [rideGallery, setRideGallery] = useState([]);

  const handleRide = async (e) => {
    e.preventDefault();
    showButton(false);
    try {
      const res = await axios.patch(`${baseUrl}/api/v1/ride/updateRide/${rideID}`, {
        Location: rideLocation,
        Name: rideName,
        Description: rideDescription,
        RideID: rideId,
      });
      if (res.data.status === "success") {
        alert("Ride updated");

        showButton(true);
      }
    } catch (error) {
      alert("Somthing went wrong");
    }
  };

  useEffect(() => {
    if (!id || !role || role2 !== "SuperAdmin") {
      navigate("/login", { replace: true, state: { signIn: true } });
    } else {
      navigate(`/updateRide/${rideID}`);
    }
  }, [navigate, id, role]);
  const logout = () => {
    dispatch(getUserIdFromAuth(""));
    navigate("/login");
  };

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const GetData = async () => {
    console.log("hello")
    try {
      const res = await axios.get(
        `https://server.youthbuzz.in/api/v1/ride/getOneRide/${rideID}`
      );
      setData([res.data.data.ride])
      console.log(res)
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    
    GetData();

  }, []);

  useEffect(() => {
    const filteredSessionData = data.filter((item) => {
      return (
        item._id.toLowerCase().includes(search.toLowerCase()) ||
        item.Date.toLowerCase().includes(search.toLowerCase()) ||
        item.Location.toLowerCase().includes(search.toLowerCase())
      );
    });

    const filteredAchievementData = data2.filter((item) => {
      return (
        item._id.toLowerCase().includes(search.toLowerCase()) ||
        item.Date.toLowerCase().includes(search.toLowerCase()) ||
        item.Location.toLowerCase().includes(search.toLowerCase()) ||
        item.Game.toLowerCase().includes(search.toLowerCase()) ||
        item.PlayerName.toLowerCase().includes(search.toLowerCase()) ||
        item.AchivementName.toLowerCase().includes(search.toLowerCase())
      );
    });

    setFilteredData({
      session: filteredSessionData,
      achievement: filteredAchievementData,
    });
  }, [search, data, data2]);

  const handleFileChange = (event) => {
    console.log(event.target.value);
    setRideThumnail(event.target.files[0]);
  };

  const handleSubmitphoto = async (e) => {
    e.preventDefault();
    showButton(false);
    const formData = new FormData();
    formData.append("Thumbnail", rideThumnail);
    try {
      const response = await axios.patch(
        `${baseUrl}/api/v1/ride/Thumbnail/${rideID}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response.data);
      if (response.data.status === "success") {
        showButton(true);
        alert("Ride thumnail uploaded");
      }
      // setFile(response.data);
    } catch (error) {
      alert("Somthing went wrong");
      showButton(true);
    }
  };
  const handleFileChange2 = (event) => {
    console.log(event.target.value);
    const files = event.target.files;
    setRideGallery(Array.from(files)); // Set state with an array of files
  };

  const handleSubmitphoto2 = async (e) => {
    e.preventDefault();
    showButton(false);

    const formData = new FormData();
    // Append each file to the formData
    rideGallery.forEach((file, index) => {
      formData.append(`Gallery`, file);
    });

    try {
      const response = await axios.patch(
        `${baseUrl}/api/v1/ride/Gallery/${rideID}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response.data);
      if (response.data.status === "success") {
        showButton(true);
        alert("Ride thumbnail uploaded");
      }
      // setFile(response.data);
    } catch (error) {
      alert("Something went wrong");
      showButton(true);
    }
  };

  useEffect(() => {
    if (data === undefined || data.length === 0) return;
    setRideName(data[0].Name !== null ? data[0].Name : "");
    setRideId(data[0].RideID !== null ? data[0].RideID : "");
    setRideDescription(data[0].Description !== null ? data[0].Description : "");
    setRideLocation(data[0].Location!==null ? data[0].Location:"")
  }, [data]);

  return (
    <>
      <div>
      <Link to="/superAdmin"><button
      
      style={{ position: "fixed", top: "10px", right: "0px" }}
       className="login-btn"
     >Back to superadmin
     </button></Link>
        <form onSubmit={handleRide} className="admin-form">
          <h2>Update ride</h2>
          <h3></h3>
          <input
            type="text"
            className="login-form"
            placeholder="Ride name"
            onChange={(e) => setRideName(e.target.value)}
            required
            value={rideName}
          ></input>
          <input
            type="text"
            className="login-form"
            placeholder="Ride Id"
            required
            onChange={(e) => setRideId(e.target.value)}
            value={rideId}
          ></input>
          <input
            type="text"
            required
            className="login-form"
            placeholder="Ride location"
            onChange={(e) => setRideLocation(e.target.value)}
            value={rideLocation}
          ></input>
          <textarea
            className="login-form"
            rows="8"
            required
            placeholder="Ride description"
            onChange={(e) => setRideDescription(e.target.value)}
            value={rideDescription}
          ></textarea>
          {button ? (
            <button
              className="button-9"
              style={{ width: "200px" }}
              type="submit"
            >
              SUBMIT
            </button>
          ) : (
            <button
              className="button-9"
              disabled
              style={{ width: "200px" }}
              type="submit"
            >
              PLEASE WAIT
            </button>
          )}
        </form>
      </div>

      <div style={{marginTop:"20px"}}>
        {" "}
        <form onSubmit={handleSubmitphoto} className="admin-form">
          <h2>Update thumnail</h2>

          <input
            type="file"
            className="login-form"
            placeholder="Thumbnail"
            onChange={handleFileChange}
            required
          ></input>
          {button ? (
            <button
              className="button-9"
              style={{ width: "200px" }}
              type="submit"
            >
              SUBMIT
            </button>
          ) : (
            <button
              className="button-9"
              disabled
              style={{ width: "200px" }}
              type="submit"
            >
              PLEASE WAIT
            </button>
          )}
        </form>
        <form
          style={{ marginTop: "20px" }}
          onSubmit={handleSubmitphoto2}
          className="admin-form"
        >
          <h2>Update gallery</h2>
          <h4>Max 5 pics </h4>
          <input
            type="file"
            required
            className="login-form"
            placeholder="Tumbnail"
            onChange={handleFileChange2}
            multiple
          ></input>
          {button ? (
            <button
              className="button-9"
              style={{ width: "200px" }}
              type="submit"
            >
              SUBMIT
            </button>
          ) : (
            <button
              className="button-9"
              disabled
              style={{ width: "200px" }}
              type="submit"
            >
              PLEASE WAIT
            </button>
          )}
        </form>
      </div>
    </>
  );
}

export default UpdateRide;
