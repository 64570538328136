import { getrideId } from "../actions/actionTypes";

const initialState = {};

export const getRidefromCreate = (state = initialState, action) => {
    switch(action.type){
        case getrideId.GET_RIDE_ID :
            return action.payload
        case getrideId.REMOVE_RIDE_ID:
            return action.payload
        default :
            return state
    }
        
};