import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getUserIdFromAuth } from "../Redux/actions/GetSellerIdFromAuthActionCreators";

function Admin() {
  const id = useSelector((state) => state.get_seller_profile_id.user_id);
  const location = useSelector((state) => state.get_seller_profile_id.location);
  const role = useSelector((state) => state.get_seller_profile_id.role);

  console.log(location)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState({
    session: [],
    achievement: [],
  });
  const [deleteshow, setDelete] = useState(true);
  const [time, setTime] = useState("UTC");

  const convertToIST = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date(0, 0, 0, hours, minutes, seconds);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
    return date.toTimeString().split(" ")[0];
  };
  const convertToSpainTime = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date(0, 0, 0, hours, minutes, seconds);
    date.setHours(date.getHours() + 2);
    date.setMinutes(date.getMinutes() + 0);
    return date.toTimeString().split(" ")[0];
  };
  const convertToUSA = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date(0, 0, 0, hours, minutes, seconds);
    date.setHours(date.getHours() - 4);
    date.setMinutes(date.getMinutes() + 0);
    return date.toTimeString().split(" ")[0];
  };
  const convertToUk = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date(0, 0, 0, hours, minutes, seconds);
    date.setHours(date.getHours() + 1);
    date.setMinutes(date.getMinutes() + 0);
    return date.toTimeString().split(" ")[0];
  };
  useEffect(() => {
    if (!id || !role || role!="ArcadeAdmin" ) {
      navigate("/login", { replace: true, state: { signIn: true } });
    } else {
      navigate("/");
    }
  }, [navigate, id]);

  const logout = () => {
    dispatch(getUserIdFromAuth(""));
    navigate("/login");
  };

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const GetData = async () => {
    try {
      const res = await axios.get(
        `https://server.youthbuzz.in/api/v1/session/get?Location=${location}`
      );
      setData(res.data.Data.session);
      console.log(res)
    } catch (error) {
      console.error(error);
    }
  };

  const GetData2 = async () => {
    try {
      const res = await axios.get(
        "https://server.youthbuzz.in/api/v1/achievement/get"
      );
      setData2(res.data.Data.session);
    } catch (error) {
      console.error(error);
    }
  };

  const delete1 = async (id) => {
    try {
      const res = await axios.delete(
        `https://server.youthbuzz.in/api/v1/session/delete/${id}`
      );
      alert("Deleted");
    } catch (error) {
      console.error(error);
    }
  };

  const delete2 = async (id) => {
    try {
      const res = await axios.delete(
        `https://server.youthbuzz.in/api/v1/achievement/delete/${id}`
      );
      alert("Deleted");
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAll1 = async () => {
    try {
      const res = await axios.delete(
        `https://server.youthbuzz.in/api/v1/session/deleteAll`
      );
      alert("Deleted all session data");
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAll2 = async () => {
    try {
      const res = await axios.delete(
        `https://server.youthbuzz.in/api/v1/achievement/deleteAll`
      );
      alert("Deleted all achievement data");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetData2();
    GetData();
  }, []);

  useEffect(() => {
    const filteredSessionData = data.filter((item) => {
      return (
        item._id.toLowerCase().includes(search.toLowerCase()) ||
        item.Date.toLowerCase().includes(search.toLowerCase()) ||
        item.Location.toLowerCase().includes(search.toLowerCase())
      );
    });

    const filteredAchievementData = data2.filter((item) => {
      return (
        item._id.toLowerCase().includes(search.toLowerCase()) ||
        item.Date.toLowerCase().includes(search.toLowerCase()) ||
        item.Location.toLowerCase().includes(search.toLowerCase()) ||
        item.Game.toLowerCase().includes(search.toLowerCase()) ||
        item.PlayerName.toLowerCase().includes(search.toLowerCase()) ||
        item.AchivementName.toLowerCase().includes(search.toLowerCase())
      );
    });

    setFilteredData({
      session: filteredSessionData,
      achievement: filteredAchievementData,
    });
  }, [search, data, data2]);

  return (
    <>
 <select value={time} onChange={(e) => setTime(e.target.value)}>
        <option value="UTC">UTC</option>
        <option value="ist">IST</option>
        <option value="Spain">Spain</option>
        <option value="usa">USA</option>
        <option value="UK">UK</option>
      </select>
      {show === "achiveDelete" && (
        <div
          style={{
            background: "white",
            color: "black",
            width: "300px",
            margin: "auto",
            padding: "50px",
          }}
        >
          Do you want to delete all achievement data?
          <button onClick={deleteAll1}>Yes</button>
          <button onClick={() => setShow("achiv")}>No</button>
        </div>
      )}
      {show === "sessionDelete" && (
        <div
          style={{
            background: "white",
            color: "black",
            width: "300px",
            margin: "auto",
            padding: "50px",
          }}
        >
          Do you want to delete all session data?
          <button onClick={deleteAll2}>Yes</button>
          <button onClick={() => setShow("session")}>No</button>
        </div>
      )}
      <button
        onClick={logout}
        style={{ position: "fixed", top: "10px", right: "0px" }}
        className="login-btn"
      >
        LOGOUT
      </button>
      <input
        type="text"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ marginBottom: "20px" }}
      />
      <button onClick={() => setShow("achiv")}>Achievements</button>
      <button onClick={() => setShow("session")}>Session</button>
      {show === "session" && (
        <div>
          <button onClick={() => setShow("sessionDelete")}>
            Delete All Session Data
          </button>
          <table
            bgcolor="#0D4F74"
            cellSpacing="0px"
            style={{ margin: "50px auto" }}
          >
            <thead>
              <tr>
                <th>Sno.</th>
                <th>Session no.</th>
                <th>Date</th>
                <th>Duration</th>
                <th>Start time</th>
                <th>End time</th>
                <th>Location</th>
                <th>Total players</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.session.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item._id}</td>
                  <td>{item.Date}</td>
                  <td>{item.Duration}</td>
                  <td>
                    {" "}
                    {time == "UK" && <>{convertToUk(item.StartTime)}</>}
                    {time == "Spain" && (
                      <>{convertToSpainTime(item.StartTime)}</>
                    )}
                      {time == "ist" && (
                      <>{convertToIST(item.StartTime)}</>
                    )}
                        {time == "usa" && (
                      <>{convertToUSA(item.StartTime)}</>
                    )}
                       {time == "UTC" && (
                      <>{item.StartTime}</>
                    )}
                  </td>
                  <td>
                    {" "}
                    {time == "UK" && <>{convertToUk(item.EndTime)}</>}
                    {time == "Spain" && (
                      <>{convertToSpainTime(item.EndTime)}</>
                    )}
                      {time == "ist" && (
                      <>{convertToIST(item.EndTime)}</>
                    )}
                        {time == "usa" && (
                      <>{convertToUSA(item.EndTime)}</>
                    )}
                       {time == "UTC" && (
                      <>{item.EndTime}</>
                    )}
                  </td>
                  <td>{item.Location}</td>
                  <td>{item.TotalPlayers}</td>
                  <td>
                    {deleteshow ? (
                      <button onClick={() => delete1(item._id)}>Delete</button>
                    ) : (
                      <button>Deleted</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {show === "achiv" && (
        <div>
          <button onClick={() => setShow("achiveDelete")}>
            Delete All Achievement Data
          </button>
          <table
            bgcolor="#0D4F74"
            cellSpacing="0px"
            style={{ margin: "50px auto" }}
          >
            <thead>
              <tr>
                <th>Sno.</th>
                <th>Achievement no.</th>
                <th>Date</th>
                <th>Time</th>
                <th>Startdatetime</th>
                <th>Enddatetime</th>
                <th>Location</th>
                <th>Game</th>
                <th>Player ID</th>
                <th>Player Name</th>
                <th>Achievement Name</th>
                <th>Achievement value</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.achievement.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item._id}</td>
                  <td>{item.Date}</td>
                  <td>{item.Time}</td>
                  <td>{item.StartdateTime}</td>
                  <td>{item.EnddateTime}</td>
                  <td>{item.Location}</td>
                  <td>{item.Game}</td>
                  <td>{item.PlayerID}</td>
                  <td>{item.PlayerName}</td>
                  <td>{item.AchivementName}</td>
                  <td>{item.AchivementValue}</td>
                  <td>{item.Remark}</td>
                  <td>
                    <button onClick={() => delete2(item._id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default Admin;
